import React, { Fragment } from 'react';
import { DataTitleCopyable } from './data-title';
import './numeric-data-entry-with-formatted-link.css';
import { FieldRow } from './field-row';
const NumericDataEntryWithFormattedLink = (props) => {
    var _a;
    const slugWithModifier = props.slug + ((_a = props.slugModifier) !== null && _a !== void 0 ? _a : '');
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        React.createElement(FieldRow, null,
            React.createElement("input", { className: "form-control narrow-input-form", type: "number", id: slugWithModifier, name: slugWithModifier, value: props.value == undefined ? '' : props.value, step: props.step == undefined ? 1 : props.step, max: props.max, min: props.min, disabled: props.mode === 'view' || props.disabled, placeholder: props.placeholder, required: props.required, onChange: e => props.onChange(props.slug, e.target.value === '' ? null : parseFloat(e.target.value)) }),
            React.createElement("div", { className: "input-lookalike with-margin" }, props.value == undefined ? React.createElement("div", null) : React.createElement("a", { className: "with-margin", href: props.linkTargetFunction(props.value), target: "_blank" }, props.linkDescriptionFunction(props.value))))));
};
export default NumericDataEntryWithFormattedLink;
