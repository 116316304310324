import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './changes.css';
import { apiGet } from '../apiHelpers';
import { BuildingEditSummary } from '../building/edit-history/building-edit-summary';
import ErrorBox from '../components/error-box';
import InfoBox from '../components/info-box';
const recordsPerPage = 20;
function getNavigationUrl(options) {
    let query = '?';
    let params = [];
    if (options == undefined)
        return query;
    if (options.afterId != undefined)
        params.push(`after_id=${options.afterId}`);
    if (options.beforeId != undefined)
        params.push(`before_id=${options.beforeId}`);
    if (options.deletions != undefined)
        params.push(`deletions=${options.deletions}`);
    return query + params.join('&');
}
const ChangesPage = (props) => {
    let { after_id, before_id, deletions } = parse(props.location.search);
    if (deletions instanceof Array)
        deletions = deletions[0];
    const [history, setHistory] = useState();
    const [paging, setPaging] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        const fetchData = async () => {
            setHistory(null);
            setPaging(null);
            let url = `/api/history?count=${recordsPerPage}`;
            if (after_id) {
                url = `${url}&after_id=${after_id}`;
            }
            if (before_id) {
                url = `${url}&before_id=${before_id}`;
            }
            if (deletions) {
                url = `${url}&deletions=${deletions}`;
            }
            try {
                const { history, paging, error } = await apiGet(url);
                if (error) {
                    setError(error);
                }
                else {
                    setHistory(history);
                    setPaging(paging);
                }
            }
            catch (err) {
                console.error('Connection problem. Please try again later...');
                setError(err);
            }
        };
        fetchData();
    }, [props.location.search]);
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            React.createElement("h1", null, "Global edit history"),
            (paging === null || paging === void 0 ? void 0 : paging.id_for_newer_query) &&
                React.createElement(Link, { className: 'edit-history-link', to: getNavigationUrl({
                        afterId: paging.id_for_newer_query,
                        deletions: deletions
                    }) }, "Show more recent changes"),
            (after_id || before_id) &&
                React.createElement(Link, { className: 'edit-history-latest-link', to: getNavigationUrl({
                        deletions: deletions
                    }) }, "Show latest changes"),
            React.createElement("ul", { className: "edit-history-list" },
                deletions === 'true' &&
                    React.createElement(InfoBox, { msg: "Showing only changes with at least one deletion" }),
                error &&
                    React.createElement(ErrorBox, { msg: error }),
                error == undefined && history == undefined &&
                    React.createElement(InfoBox, { msg: "Loading history..." }),
                ((history === null || history === void 0 ? void 0 : history.length) === 0) &&
                    React.createElement(InfoBox, { msg: "No changes so far" }),
                (history != undefined && history.length > 0) &&
                    history.map(entry => (React.createElement("li", { key: `${entry.revision_id}`, className: "edit-history-list-element" },
                        React.createElement(BuildingEditSummary, { historyEntry: entry, showBuildingId: true, hyperlinkCategories: true }))))),
            (paging === null || paging === void 0 ? void 0 : paging.id_for_older_query) &&
                React.createElement(Link, { to: getNavigationUrl({
                        beforeId: paging.id_for_older_query,
                        deletions: deletions
                    }) }, "Show older changes"))));
};
export default ChangesPage;
