import React from 'react';
import { Link } from 'react-router-dom';
import './category-edit-summary.css';
import { categoriesConfig } from '../../config/categories-config';
import { FieldEditSummary } from './field-edit-summary';
const CategoryEditSummary = props => {
    var _a;
    const { name: categoryName = 'Unknown category', slug: categorySlug = 'categories' } = (_a = categoriesConfig[props.category]) !== null && _a !== void 0 ? _a : {};
    return (React.createElement("div", { className: 'edit-history-category-summary' },
        React.createElement("h3", { className: 'edit-history-category-title' },
            props.hyperlinkCategory && props.hyperlinkTemplate != undefined ?
                React.createElement(Link, { to: props.hyperlinkTemplate.replace(/\$category/, categorySlug) }, categoryName) :
                categoryName,
            ":"),
        React.createElement("ul", null, props.fields.map(x => React.createElement("li", { key: x.title },
            React.createElement(FieldEditSummary, { title: x.title, value: x.value, oldValue: x.oldValue }))))));
};
export { CategoryEditSummary };
