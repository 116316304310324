import { useEffect, useState } from 'react';
export function useLastNotEmpty(value) {
    const [notEmpty, setNotEmpty] = useState(value);
    useEffect(() => {
        if (value != undefined) {
            setNotEmpty(value);
        }
    }, [value]);
    return notEmpty;
}
