import React, { Fragment } from 'react';
import '../../map/map-button.css';
import withCopyEdit from '../data-container';
import UserOpinionEntry from '../data-components/user-opinion-data-entry';
import { MultiSelectDataEntry } from '../data-components/multi-select-data-entry';
import { DataEntryGroup } from '../data-components/data-entry-group';
import { LogicalDataEntry, LogicalDataEntryYesOnlyWithExplanation } from '../data-components/logical-data-entry/logical-data-entry';
import { buildingUserFields, dataFields } from '../../config/data-fields-config';
import './community.css';
import SelectDataEntry from '../data-components/select-data-entry';
import Verification from '../data-components/verification';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import { useDisplayPreferences } from '../../displayPreferences-context';
/**
* Community view/edit section
*/
const CommunityView = (props) => {
    var _a;
    const switchToTypologyMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('typology_likes');
    };
    const switchToLocalSignificanceMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_local_significance_total');
    };
    const switchToExpectedApplicationMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_expected_planning_application_total');
    };
    const switchToPublicOwnershipMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_in_public_ownership');
    };
    const { darkLightTheme } = useDisplayPreferences();
    const worthKeepingReasonsNonEmpty = Object.values((_a = props.building.community_type_worth_keeping_reasons) !== null && _a !== void 0 ? _a : {}).some(x => x);
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Community views on how well buildings work" },
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement("i", null, "Note: We are currently only collecting data on non-residential buildings.")),
            React.createElement("div", { className: 'community-opinion-pane' },
                (props.building.is_domestic === "no" || props.building.is_domestic === "mixed domestic/non-domestic") ?
                    React.createElement(React.Fragment, null,
                        React.createElement(UserOpinionEntry, { slug: 'community_like', title: buildingUserFields.community_like.title, userValue: props.building.community_like, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }))
                    :
                        React.createElement(React.Fragment, null),
                React.createElement(LogicalDataEntryYesOnlyWithExplanation, { slug: 'community_type_worth_keeping', title: buildingUserFields.community_type_worth_keeping.title, value: props.building.community_type_worth_keeping, disallowFalse: worthKeepingReasonsNonEmpty, disallowNull: worthKeepingReasonsNonEmpty, onChange: props.onSaveChange, mode: props.mode }),
                (props.mapColourScale == "typology_likes") ?
                    React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToLocalSignificanceMapStyle }, 'Click here to change map to buildings of local interest.')
                    :
                        React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToTypologyMapStyle }, "Click to return to liked typologies mapped."),
                props.building.community_type_worth_keeping === true &&
                    React.createElement(MultiSelectDataEntry, { slug: 'community_type_worth_keeping_reasons', title: buildingUserFields.community_type_worth_keeping_reasons.title, value: props.building.community_type_worth_keeping_reasons, disabled: !props.building.community_type_worth_keeping, onChange: props.onSaveChange, options: Object.entries(buildingUserFields.community_type_worth_keeping_reasons.fields)
                            .map(([key, definition]) => ({
                            key,
                            label: definition.title
                        })), mode: props.mode }),
                React.createElement("hr", null),
                React.createElement(UserOpinionEntry, { slug: 'community_local_significance', title: buildingUserFields.community_local_significance.title, userValue: props.building.community_local_significance, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }),
                (props.mapColourScale == "community_local_significance_total") ?
                    React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToTypologyMapStyle }, 'Click to return to liked typologies mapped.')
                    :
                        React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToLocalSignificanceMapStyle }, "Click here to change map to buildings of local interest."),
                React.createElement("hr", null),
                React.createElement(UserOpinionEntry, { slug: 'community_expected_planning_application', title: buildingUserFields.community_expected_planning_application.title, userValue: props.building.community_expected_planning_application, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }),
                React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                    React.createElement("i", null, "For more information on current planning applications, refer to the Planning Controls category.")),
                (props.mapColourScale == "community_expected_planning_application_total") ?
                    React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToTypologyMapStyle }, 'Click to return to liked typologies mapped.')
                    :
                        React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToExpectedApplicationMapStyle }, "Click here to change map to planning applications expected by community."))),
        React.createElement(DataEntryGroup, { name: "Buildings in community use" },
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement("i", null, "Here we are collecting information on the location of buildings used for community activities so we can track loss of/additions to community space over time.")),
            React.createElement(LogicalDataEntry, { slug: 'community_activities_current', title: dataFields.community_activities_current.title, tooltip: dataFields.community_activities_current.tooltip, value: props.building.community_activities_current, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(LogicalDataEntry, { slug: 'community_activities', title: dataFields.community_activities.title, tooltip: dataFields.community_activities.tooltip, value: props.building.community_activities, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(LogicalDataEntry, { slug: 'community_activities_always', title: dataFields.community_activities_always.title, tooltip: dataFields.community_activities_always.tooltip, value: props.building.community_activities_always, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(SelectDataEntry, { slug: 'community_public_ownership', title: dataFields.community_public_ownership.title, value: props.building.community_public_ownership, options: dataFields.community_public_ownership.items, onChange: props.onChange, mode: props.mode, copy: props.copy }),
            React.createElement(Verification, { slug: "community_public_ownership", allow_verify: props.user !== undefined && props.building.community_public_ownership !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("community_public_ownership"), user_verified_as: props.user_verified.community_public_ownership, verified_count: props.building.verified.community_public_ownership }),
            React.createElement(MultiDataEntry, { slug: 'community_public_ownership_sources', title: dataFields.community_public_ownership_sources.title, isUrl: true, placeholder: 'https://...', editableEntries: true, value: props.building.community_public_ownership_sources, onChange: props.onChange, mode: props.mode, copy: props.copy }),
            React.createElement(Verification, { slug: "community_public_ownership_sources", allow_verify: props.user !== undefined && props.building.community_public_ownership_sources !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("community_public_ownership_sources"), user_verified_as: props.user_verified.community_public_ownership_sources, verified_count: props.building.verified.community_public_ownership_sources }),
            (props.mapColourScale == "community_in_public_ownership") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToTypologyMapStyle }, 'Click to return to liked typologies mapped.')
                :
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToPublicOwnershipMapStyle }, "Click here to see ownership type mapped."))));
};
const CommunityContainer = withCopyEdit(CommunityView);
export default CommunityContainer;
