import React, { Fragment } from 'react';
import { commonSourceTypes, dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import Verification from '../data-components/verification';
import withCopyEdit from '../data-container';
import { DataEntryGroup } from '../data-components/data-entry-group';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import { useDisplayPreferences } from '../../displayPreferences-context';
/**
* Type view/edit section
*/
const TypeView = (props) => {
    const { darkLightTheme } = useDisplayPreferences();
    const switchToClassificationMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('typology_classification');
    };
    // const switchToStylePeriodMapStyle = (e) => {
    //     e.preventDefault();
    //     props.onMapColourScale('typology_style_period')
    // }
    const switchToDynamicClassificationMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('typology_dynamic_classification');
    };
    const switchToAttachmentMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('building_attachment_form');
    };
    const switchToLandUseMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('original_landuse');
    };
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Basic typology classification" },
            (props.mapColourScale == "typology_classification") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToDynamicClassificationMapStyle }, 'Click here to change map to show dynamic tissue classification.')
                :
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToClassificationMapStyle }, "Click to change map to show typology classification."),
            React.createElement(SelectDataEntry, { title: dataFields.typology_classification.title, slug: "typology_classification", value: props.building.typology_classification, tooltip: dataFields.typology_classification.tooltip, options: dataFields.typology_classification.items, mode: props.mode, copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "typology_classification", allow_verify: props.user !== undefined && props.building.typology_classification !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("typology_classification"), user_verified_as: props.user_verified.typology_classification, verified_count: props.building.verified.typology_classification }),
            React.createElement(SelectDataEntry, { title: dataFields.typology_classification_source_type.title, slug: "typology_classification_source_type", value: props.building.typology_classification_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_classification_source_type.tooltip, placeholder: dataFields.typology_classification_source_type.example, options: dataFields.typology_classification_source_type.items }),
            (props.building.typology_classification_source_type == commonSourceTypes[0] ||
                props.building.typology_classification_source_type == commonSourceTypes[1] ||
                props.building.typology_classification_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.typology_classification_source_links.title, slug: "typology_classification_source_links", value: props.building.typology_classification_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_classification_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Architectural style" },
            React.createElement(SelectDataEntry, { title: dataFields.typology_style_period.title, slug: "typology_style_period", value: props.building.typology_style_period, tooltip: dataFields.typology_style_period.tooltip, options: dataFields.typology_style_period.items, mode: props.mode, copy: props.copy, onChange: props.onChange, disabled: true }),
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 14, backgroundColor: "#f6f8f9" } },
                React.createElement("i", { className: "source-url" },
                    "To edit the architectural style box, and to see the data mapped, please go to ",
                    React.createElement("a", { href: "/" + props.mode + "/age/" + props.building.building_id }, "Age & History"),
                    "."))),
        React.createElement(DataEntryGroup, { name: "Dynamic tissue classification" },
            (props.mapColourScale == "typology_dynamic_classification") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToClassificationMapStyle }, 'Click to change map to show typology classification.')
                :
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToDynamicClassificationMapStyle }, "Click here to change map to show dynamic classification."),
            React.createElement(SelectDataEntry, { title: dataFields.typology_dynamic_classification.title, slug: "typology_dynamic_classification", value: props.building.typology_dynamic_classification, tooltip: dataFields.typology_dynamic_classification.tooltip, options: dataFields.typology_dynamic_classification.items, mode: props.mode, copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "typology_dynamic_classification", allow_verify: props.user !== undefined && props.building.typology_dynamic_classification !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("typology_dynamic_classification"), user_verified_as: props.user_verified.typology_dynamic_classification, verified_count: props.building.verified.typology_dynamic_classification }),
            React.createElement(SelectDataEntry, { title: dataFields.typology_dynamic_classification_source_type.title, slug: "typology_dynamic_classification_source_type", value: props.building.typology_dynamic_classification_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_dynamic_classification_source_type.tooltip, placeholder: dataFields.typology_dynamic_classification_source_type.example, options: dataFields.typology_dynamic_classification_source_type.items }),
            (props.building.typology_dynamic_classification_source_type == commonSourceTypes[0] ||
                props.building.typology_dynamic_classification_source_type == commonSourceTypes[1] ||
                props.building.typology_dynamic_classification_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.typology_dynamic_classification_source_links.title, slug: "typology_dynamic_classification_source_links", value: props.building.typology_dynamic_classification_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_dynamic_classification_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Original Use" },
            (props.mapColourScale == "original_landuse") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToClassificationMapStyle }, 'Click to change map to show typology classification.')
                :
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToLandUseMapStyle }, "Click here to change map to original land use."),
            React.createElement(MultiDataEntry, { title: dataFields.typology_original_use.title, slug: "typology_original_use", value: props.building.typology_original_use, mode: props.mode, copy: props.copy, onChange: props.onChange, confirmOnEnter: true, tooltip: dataFields.typology_original_use.tooltip, placeholder: "Type new land use group here", copyable: true, autofill: true, showAllOptionsOnEmpty: true }),
            React.createElement(Verification, { slug: "typology_original_use", allow_verify: props.user !== undefined && props.building.typology_original_use !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("typology_original_use"), user_verified_as: props.user_verified.typology_original_use, verified_count: props.building.verified.typology_original_use }),
            React.createElement(SelectDataEntry, { title: dataFields.typology_original_use_source_type.title, slug: "typology_original_use_source_type", value: props.building.typology_original_use_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_original_use_source_type.tooltip, placeholder: dataFields.typology_original_use_source_type.example, options: dataFields.typology_original_use_source_type.items }),
            (props.building.typology_original_use_source_type == commonSourceTypes[0] ||
                props.building.typology_original_use_source_type == commonSourceTypes[1] ||
                props.building.typology_original_use_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.typology_original_use_source_links.title, slug: "typology_original_use_source_links", value: props.building.typology_original_use_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_original_use_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            props.mode != 'view' &&
                React.createElement("div", null,
                    React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                        React.createElement("i", null, "Below is a more general classification for the original land use of this building, automatically derived from the information above."))),
            React.createElement(DataEntry, { title: dataFields.typology_original_use_order.title, tooltip: dataFields.typology_original_use_order.tooltip, slug: "typology_original_use_order", value: props.building.typology_original_use_order, mode: props.mode, disabled: true, copy: props.copy, onChange: props.onChange })),
        React.createElement(DataEntryGroup, { name: "Attachment/Adjacency" },
            (props.mapColourScale == "building_attachment_form") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToClassificationMapStyle }, 'Click to change map to show typology classification.')
                :
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToAttachmentMapStyle }, "Click here to change map to show attachment/adjacency."),
            React.createElement(SelectDataEntry, { title: dataFields.building_attachment_form.title, slug: "building_attachment_form", value: props.building.building_attachment_form, tooltip: dataFields.building_attachment_form.tooltip, options: dataFields.building_attachment_form.items, mode: props.mode, copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "building_attachment_form", allow_verify: props.user !== undefined && props.building.building_attachment_form !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("building_attachment_form"), user_verified_as: props.user_verified.building_attachment_form, verified_count: props.building.verified.building_attachment_form }),
            React.createElement(SelectDataEntry, { title: dataFields.building_attachment_source_type.title, slug: "building_attachment_source_type", value: props.building.building_attachment_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.building_attachment_source_type.tooltip, placeholder: dataFields.building_attachment_source_type.example, options: dataFields.building_attachment_source_type.items }),
            (props.building.building_attachment_source_type == commonSourceTypes[0] ||
                props.building.building_attachment_source_type == commonSourceTypes[1] ||
                props.building.building_attachment_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.building_attachment_source_links.title, slug: "building_attachment_source_links", value: props.building.building_attachment_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.building_attachment_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })))));
};
const TypeContainer = withCopyEdit(TypeView);
export default TypeContainer;
