import React from 'react';
const OrdnanceSurveyLicencePage = () => (React.createElement("article", { className: "main-col" },
    React.createElement("section", null,
        React.createElement("h1", { className: "h2" }, "Ordnance Survey Data - Terms and Conditions"),
        React.createElement("ul", null,
            React.createElement("li", null,
                "You are granted a non-exclusive, royalty free revocable licence solely to ",
                React.createElement("strong", null, "view"),
                " the licensed mapping (and addressing) data for non-commercial purposes for the period during which we make it available;"),
            React.createElement("li", null, "You are not permitted to copy, sub-license, distribute, sell or otherwise make available such data to third parties in any form; and"),
            React.createElement("li", null, "Third party rights to enforce the terms of this licence shall be reserved to OS.")))));
export default OrdnanceSurveyLicencePage;
