import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth-context';
import ErrorBox from '../components/error-box';
import { SpinnerIcon } from '../components/icons';
import InfoBox from '../components/info-box';
import SupporterLogos from '../components/supporter-logos';
let config = require('../../cc-config.json');
export const SignUp = () => {
    const { isLoading, signup } = useAuth();
    const [error, setError] = useState(undefined);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmConditions, setConfirmConditions] = useState(false);
    const onSubmit = useCallback(e => {
        e.preventDefault();
        signup({ username, email, confirmEmail, password }, setError);
    }, [username, email, confirmEmail, password, confirmConditions, signup]);
    const msgName = `Welcome to Colouring ${config.cityName}. You're one of the first people to sign up!`;
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            React.createElement("h1", { className: "h2" }, "Sign up"),
            React.createElement(InfoBox, { msg: msgName },
                React.createElement("br", null),
                "Please ",
                React.createElement("a", { href: "https://discuss.colouring.london/" }, "discuss suggestions for improvements"),
                " and ",
                React.createElement("a", { href: "https://github.com/colouring-cities/colouring-core/issues" }, "report issues or problems"),
                "."),
            React.createElement("p", null, "Create an account to start colouring in."),
            React.createElement(ErrorBox, { msg: error }),
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement("label", { htmlFor: "username" }, "Username*"),
                React.createElement("input", { name: "username", id: "username", className: "form-control", type: "text", value: username, onChange: e => setUsername(e.target.value), placeholder: "not-your-real-name", required: true, minLength: 4, maxLength: 29, pattern: "\\w+", title: "Usernames can contain only letters, numbers and the underscore" }),
                React.createElement("label", { htmlFor: "email" }, "Email (optional)"),
                React.createElement("input", { name: "email", id: "email", className: "form-control", type: "email", value: email, onChange: e => setEmail(e.target.value), placeholder: "someone@example.com" }),
                React.createElement(InfoBox, { msg: "Our policy is to avoid collecting personal data wherever possible. An email address is only necessary if you wish to be able to recover your account should you forget your password." }),
                React.createElement("label", { htmlFor: "confirm_email" }, "Confirm email (optional)"),
                React.createElement("input", { name: "confirm_email", id: "confirm_email", className: "form-control", type: "email", value: confirmEmail, onChange: e => setConfirmEmail(e.target.value) }),
                React.createElement("label", { htmlFor: "password" }, "Password (at least 8 characters)"),
                React.createElement("input", { name: "password", id: "password", className: "form-control", type: (showPassword) ? 'text' : 'password', value: password, onChange: e => setPassword(e.target.value), required: true, minLength: 8, maxLength: 128 }),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "show_password", name: "show_password", className: "form-check-input", type: "checkbox", checked: showPassword, onChange: e => setShowPassword(e.target.checked) }),
                    React.createElement("label", { className: "form-check-label", htmlFor: "show_password" }, "Show password?")),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "confirm_conditions", name: "confirm_conditions", className: "form-check-input", type: "checkbox", checked: confirmConditions, onChange: e => setConfirmConditions(e.target.checked), required: true }),
                    React.createElement("label", { className: "form-check-label", htmlFor: "confirm_conditions" },
                        "I confirm that I have read and agree to the ",
                        React.createElement(Link, { to: "/privacy-policy.html" }, "privacy policy"),
                        ", ",
                        React.createElement(Link, { to: "/contributor-agreement.html" }, "contributor agreement"),
                        " and ",
                        React.createElement(Link, { to: "/data-accuracy.html" }, "data accuracy agreement"),
                        ".")),
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement("input", { type: "submit", disabled: isLoading, value: "Sign Up", className: "btn btn-primary" }),
                    isLoading && React.createElement("span", null,
                        React.createElement(SpinnerIcon, null),
                        "Sending sign up data...")),
                React.createElement(InfoBox, { msg: "" },
                    "Please also read our ",
                    React.createElement("a", { href: "https://www.pages.colouring.london/data-ethics" }, "data ethics policy"),
                    " before using or sharing our data"),
                "Do you already have an account?",
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement(Link, { to: "login.html", className: "btn btn-outline-dark" }, "Log in")))),
        React.createElement("hr", null),
        React.createElement("section", { className: "main-col" },
            React.createElement(SupporterLogos, null))));
};
