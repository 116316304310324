import React from 'react';
import { NavLink } from 'react-router-dom';
import { EditIcon, ViewIcon } from '../../components/icons';
const ViewEditControl = props => ((props.mode === 'edit') ?
    React.createElement(NavLink, { className: "icon-button view", title: "View data", to: `/view/${props.cat}/${props.building.building_id}` },
        "View",
        React.createElement(ViewIcon, null))
    : React.createElement(NavLink, { className: "icon-button edit", title: "Edit data", to: `/edit/${props.cat}/${props.building.building_id}` },
        "Edit",
        React.createElement(EditIcon, null)));
export { ViewEditControl };
