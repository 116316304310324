import React, { Component, Fragment } from 'react';
import Verification from './verification';
import { dataFields } from '../../config/data-fields-config';
import NumericDataEntry from './numeric-data-entry';
class YearDataEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: props.year,
            upper: props.upper,
            lower: props.lower,
            decade: Math.floor(props.year / 10) * 10,
            century: Math.floor(props.year / 100) * 100
        };
    }
    // TODO add dropdown for decade, century
    // TODO roll in first/last year estimate
    // TODO handle changes internally, reporting out date_year, date_upper, date_lower
    render() {
        const props = this.props;
        const currentYear = new Date().getFullYear();
        return (React.createElement(Fragment, null,
            React.createElement(NumericDataEntry, { title: dataFields.date_year.title, slug: "date_year", value: props.year, mode: props.mode, copy: props.copy, onChange: props.onChange, min: 1, max: currentYear, tooltip: dataFields.date_year.tooltip }),
            React.createElement(Verification, { allow_verify: props.allow_verify, slug: "date_year", onVerify: props.onVerify, user_verified: props.user_verified, user_verified_as: props.user_verified_as, verified_count: props.verified_count }),
            React.createElement(NumericDataEntry, { title: dataFields.date_lower.title, slug: "date_lower", value: props.lower, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.date_lower.tooltip }),
            React.createElement(Verification, { slug: "date_lower", allow_verify: props.allow_verify_lower, onVerify: props.onVerify_lower, user_verified: props.user_verified_lower, user_verified_as: props.user_verified_as_lower, verified_count: props.verified_count_lower }),
            React.createElement(NumericDataEntry, { title: dataFields.date_upper.title, slug: "date_upper", value: props.upper, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.date_upper.tooltip }),
            React.createElement(Verification, { slug: "date_upper", allow_verify: props.allow_verify_upper, onVerify: props.onVerify_upper, user_verified: props.user_verified_upper, user_verified_as: props.user_verified_as_upper, verified_count: props.verified_count_upper })));
    }
}
export default YearDataEntry;
