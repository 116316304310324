var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SpinnerIcon } from './components/icons';
import { useAuth } from './auth-context';
export const PrivateRoute = (_a) => {
    var { component: Component, children } = _a, rest = __rest(_a, ["component", "children"]);
    const { isAuthenticated, isLoading } = useAuth();
    return React.createElement(Route, Object.assign({}, rest, { render: props => {
            if (isAuthenticated) {
                if (Component) {
                    return React.createElement(Component, Object.assign({}, props));
                }
                else if (children) {
                    return React.createElement(React.Fragment, null, children);
                }
            }
            else {
                if (isLoading) {
                    return React.createElement(Fragment, null,
                        React.createElement(SpinnerIcon, { spin: true }),
                        " Loading user info...");
                }
                return React.createElement(Redirect, { to: {
                        pathname: "/login.html",
                        state: { from: props.location.pathname }
                    } });
            }
        } }));
};
export const AuthRoute = (_a) => {
    var { component: Component, children } = _a, rest = __rest(_a, ["component", "children"]);
    const { isAuthenticated } = useAuth();
    return React.createElement(Route, Object.assign({}, rest, { render: props => {
            if (isAuthenticated) {
                let state = props.location.state;
                let from = '/my-account.html';
                if (typeof state == 'object' && state !== null && 'from' in state) {
                    from = state.from;
                }
                return React.createElement(Redirect, { to: { pathname: from } });
            }
            else {
                if (Component) {
                    return React.createElement(Component, Object.assign({}, props));
                }
                else if (children) {
                    return React.createElement(React.Fragment, null, children);
                }
            }
        } }));
};
