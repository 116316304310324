export function apiGet(path, options) {
    return apiRequest(path, 'GET', null, options);
}
export function apiPost(path, data, options) {
    return apiRequest(path, 'POST', data, options);
}
export function apiDelete(path, options) {
    return apiRequest(path, 'DELETE', null, options);
}
async function apiRequest(path, method, data, options) {
    const res = await fetch(path, {
        method: method,
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        body: data == undefined ? null : JSON.stringify(data),
    });
    const reviver = options == undefined ? undefined : options.jsonReviver;
    if (reviver != undefined) {
        return JSON.parse(await res.text(), reviver);
    }
    else {
        return await res.json();
    }
}
