/**
 * Mini-library of icons
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight, faCaretDown, faCaretRight, faCaretUp, faCheck, faCheckCircle, faCheckDouble, faEye, faInfoCircle, faPaintBrush, faQuestionCircle, faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
library.add(faQuestionCircle, faInfoCircle, faPaintBrush, faTimes, faCheck, faCheckCircle, faCheckDouble, faAngleLeft, faAngleRight, faCaretDown, faCaretUp, faCaretRight, faSearch, faEye, faSpinner);
const HelpIcon = () => (React.createElement(FontAwesomeIcon, { icon: "question-circle" }));
const InfoIcon = () => (React.createElement(FontAwesomeIcon, { icon: "info-circle" }));
const EditIcon = () => (React.createElement(FontAwesomeIcon, { icon: "paint-brush" }));
const ViewIcon = () => (React.createElement(FontAwesomeIcon, { icon: "eye" }));
const CloseIcon = () => (React.createElement(FontAwesomeIcon, { icon: "times" }));
const SaveIcon = () => (React.createElement(FontAwesomeIcon, { icon: "check" }));
const SaveDoneIcon = () => (React.createElement(FontAwesomeIcon, { icon: "check-double" }));
const VerifyIcon = () => (React.createElement(FontAwesomeIcon, { icon: "check-circle" }));
const BackIcon = () => (React.createElement(FontAwesomeIcon, { icon: "angle-left" }));
const ForwardIcon = () => (React.createElement(FontAwesomeIcon, { icon: "angle-right" }));
const DownIcon = () => (React.createElement(FontAwesomeIcon, { icon: "caret-down" }));
const UpIcon = () => (React.createElement(FontAwesomeIcon, { icon: "caret-up" }));
const RightIcon = () => (React.createElement(FontAwesomeIcon, { icon: "caret-right" }));
const SearchIcon = () => (React.createElement(FontAwesomeIcon, { icon: "search" }));
const SpinnerIcon = ({ spin = true }) => (React.createElement(FontAwesomeIcon, { icon: "spinner", spin: spin }));
export { HelpIcon, InfoIcon, EditIcon, ViewIcon, CloseIcon, SaveIcon, SaveDoneIcon, BackIcon, ForwardIcon, DownIcon, UpIcon, RightIcon, SearchIcon, VerifyIcon, SpinnerIcon };
