import React from 'react';
import { DataTitleCopyable } from './data-title';
export const PatternDataEntry = props => {
    const handleChange = (value) => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, props.slug, transformValue(value));
    };
    const transformValue = (value) => {
        const transform = props.valueTransform || (x => x);
        const transformedValue = value === '' ?
            null :
            transform(value);
        return transformedValue;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined || props.value == '', copy: props.copy }),
        React.createElement("input", { className: "form-control", type: props.isUrl ? "url" : "text", value: props.value || '', required: props.required, disabled: props.mode === 'view' || props.disabled, placeholder: props.placeholder, pattern: props.pattern, maxLength: props.maxLength, onChange: e => handleChange(e.target.value) })));
};
