import React, { Component } from 'react';
import './tooltip.css';
import { InfoIcon } from './icons';
import Markdown from 'markdown-to-jsx';
const nonCaptureLingRegex = /\[[^[]+?\]\([^(]+?\)/;
const linkRegex = /\[([^[]+?)\]\(([^(]+?)\)/;
function markdownLinkToAnchor(link) {
    const m = link.match(linkRegex);
    return (React.createElement("a", { href: m[2], target: "_blank" }, m[1]));
}
function interweave(arr1, arr2) {
    const commonLen = Math.min(arr1.length, arr2.length);
    const arr = [];
    for (let i = 0; i < commonLen; i++) {
        arr.push(arr1[i], arr2[i]);
    }
    arr.push(...arr1.slice(commonLen), ...arr2.slice(commonLen));
    return arr;
}
function tooltipTextToComponents(text) {
    let betweenLinks = text.split(nonCaptureLingRegex);
    if (betweenLinks.length <= 1)
        return [text];
    let links = text.match(new RegExp(linkRegex, 'g')).map(markdownLinkToAnchor);
    return interweave(betweenLinks, links);
}
class Tooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
        this.toggleVisible = this.toggleVisible.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }
    toggleVisible() {
        this.setState(state => ({
            active: !state.active
        }));
    }
    handleBlur(event) {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            this.setState({
                active: false
            });
        }
    }
    render() {
        return (React.createElement("div", { className: "tooltip-wrap", tabIndex: 0, onBlur: this.handleBlur },
            React.createElement("button", { type: "button", className: (this.state.active ? 'active ' : '') + 'tooltip-hint icon-button', onClick: this.toggleVisible },
                "Hint",
                React.createElement(InfoIcon, null)),
            this.state.active ?
                (React.createElement("div", { className: "tooltip bs-tooltip-bottom" },
                    React.createElement("div", { className: "arrow" }),
                    React.createElement("div", { className: "tooltip-inner" },
                        React.createElement(Markdown, null, this.props.text))))
                : null));
    }
}
export default Tooltip;
