import React, { useState, Fragment } from 'react';
import './sidebar.css';
import { BackIcon, ForwardIcon } from '../components/icons';
const Sidebar = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    return (React.createElement(Fragment, null,
        React.createElement("div", { id: "sidebar", className: "info-container " + (collapsed ? "offscreen" : "") },
            React.createElement("button", { className: "info-container-collapse btn btn-light", onClick: () => setCollapsed(!collapsed) }, collapsed ?
                React.createElement(ForwardIcon, null)
                : React.createElement(BackIcon, null)),
            React.createElement("div", { className: "info-container-inner" }, props.children))));
};
export default Sidebar;
