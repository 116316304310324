import React from 'react';
const ErrorBox = (props) => {
    if (props.msg) {
        console.error(props.msg);
    }
    return props.msg ?
        React.createElement("div", { className: "alert alert-danger", role: "alert" }, typeof props.msg === 'string' ?
            props.msg
            : 'Unexpected error') :
        null;
};
export default ErrorBox;
