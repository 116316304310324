import React, { Fragment } from 'react';
import { DataEntryInput } from './data-entry-input';
import { DataTitleCopyable } from './data-title';
const DataEntry = (props) => {
    var _a;
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined || props.value == '', copy: props.copy }),
        React.createElement(DataEntryInput, { slug: props.slug, name: (_a = props.slug + props.slugModifier) !== null && _a !== void 0 ? _a : '', value: props.value, onChange: props.onChange, disabled: props.mode === 'view' || props.disabled, maxLength: props.maxLength, placeholder: props.placeholder, isUrl: props.isUrl, required: props.required, valueTransform: props.valueTransform })));
};
export default DataEntry;
