import React, { Fragment } from 'react';
import { DataTitleCopyable } from './data-title';
const UPRNsDataEntry = (props) => {
    const uprns = props.value || [];
    const noParent = uprns.filter(uprn => uprn.parent_uprn == null);
    const withParent = uprns.filter(uprn => uprn.parent_uprn != null);
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, title: props.title, tooltip: props.tooltip }),
        React.createElement("dd", null,
            noParent.length ?
                React.createElement("ul", { className: "uprn-list" }, noParent.map(uprn => (React.createElement("li", { key: uprn.uprn }, uprn.uprn))))
                : '\u00A0',
            withParent.length ?
                React.createElement("details", null,
                    React.createElement("summary", null, "Children"),
                    React.createElement("ul", { className: "uprn-list" }, withParent.map(uprn => (React.createElement("li", { key: uprn.uprn },
                        uprn.uprn,
                        " (child of ",
                        uprn.parent_uprn,
                        ")")))))
                : null)));
};
export default UPRNsDataEntry;
