import React from 'react';
import { Link } from 'react-router-dom';
import { BackIcon } from '../components/icons';
import './container-header.css';
const ContainerHeader = (props) => (React.createElement("header", { className: `section-header view ${props.cat ? props.cat : ''} ${props.cat ? `background-${props.cat}` : ''}` },
    React.createElement("h2", { className: "h2" },
        props.backLink &&
            React.createElement(Link, { className: "icon-button back", to: props.backLink },
                React.createElement(BackIcon, null)),
        props.title),
    React.createElement("nav", { className: "section-header-actions" }, props.children)));
export default ContainerHeader;
