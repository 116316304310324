import React, { Fragment } from 'react';
import InfoBox from '../../components/info-box';
const { useState } = React;
const LongText = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);
    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    if (content == null) {
        return React.createElement("div", null, MissingData);
    }
    if (content.length <= limit) {
        return React.createElement("div", null, content);
    }
    if (showAll) {
        return React.createElement("div", null,
            content,
            React.createElement("br", null),
            React.createElement("b", { onClick: showLess }, "Shorten description"));
    }
    const toShow = content.substring(0, limit).trim() + "... ";
    return React.createElement("div", null,
        toShow,
        React.createElement("br", null),
        React.createElement("b", { onClick: showMore }, "Show full description"));
};
const Disclaimer = () => { return React.createElement(Fragment, null,
    React.createElement("i", null,
        React.createElement("div", null,
            React.createElement("u", null, "Disclaimer"),
            ": Not all applications for London are displayed. Some boroughs do not yet provide planning data to the GLA. For comprehensive information on applications please visit the relevant local authority's planning website."))); };
const MissingData = "not provided by data source";
function ShowIfAvailable(data) {
    return React.createElement(React.Fragment, null, data ? data.toString() : MissingData);
}
const LinkIfAvailable = (link) => {
    return React.createElement(React.Fragment, null, link ? React.createElement("a", { href: link.toString(), target: "_blank" }, link.toString()) : MissingData);
};
const StatusInfo = ({ status, statusBeforeAliasing }) => {
    if (status == null) {
        return React.createElement(React.Fragment, null, LinkIfAvailable(null));
    }
    if (status != statusBeforeAliasing) {
        return React.createElement(React.Fragment, null,
            status,
            " - status in data source was: ",
            statusBeforeAliasing);
    }
    return React.createElement(React.Fragment, null, status);
};
const PlanningDataOfficialDataEntry = (props) => {
    const data = props.shownData || [];
    if (data.length == 0) {
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement(Disclaimer, null)),
            React.createElement(InfoBox, { type: 'success' }, props.messageOnMissingData)));
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
            React.createElement("div", null,
                React.createElement("i", null,
                    "Planning application status is streamed data uploaded by local authorities to ",
                    data[0]["data_source_link"] ? React.createElement("a", { href: data[0]["data_source_link"] }, data[0]["data_source"]) : data[0]["data_source"],
                    ".")),
            React.createElement(Disclaimer, null)),
        data.map((item) => (React.createElement(Fragment, null,
            React.createElement(InfoBox, { type: 'success' },
                React.createElement(Fragment, null,
                    React.createElement("div", null,
                        React.createElement("b", null, "Planning application status for this site:"),
                        " ",
                        React.createElement(StatusInfo, { statusBeforeAliasing: item["status_before_aliasing"], status: item["status"] })),
                    item["status_explanation_note"] ? React.createElement("div", null,
                        React.createElement("b", null, "Explanation"),
                        ": ",
                        item["status_explanation_note"]) : React.createElement(React.Fragment, null),
                    React.createElement("div", null,
                        React.createElement("b", null, "Planning application ID:"),
                        " ",
                        ShowIfAvailable(item["planning_application_id"])),
                    React.createElement("div", null,
                        React.createElement("b", null, "Date registered by the planning authority (validation date)"),
                        ": ",
                        ShowIfAvailable(item["registered_with_local_authority_date"])),
                    React.createElement("div", null,
                        React.createElement("b", null, "Decision date"),
                        ": ",
                        ShowIfAvailable(item["decision_date"])),
                    React.createElement("div", null,
                        React.createElement("b", null, "Planning application link"),
                        ": ",
                        LinkIfAvailable(item["planning_application_link"])),
                    React.createElement("div", null,
                        React.createElement("b", null, "Description of proposed work"),
                        ": ",
                        item["description"] ? React.createElement(LongText, { content: item["description"], limit: 400 }) : MissingData),
                    React.createElement("div", null,
                        React.createElement("b", null, "Address of the location as provided by local authority:"),
                        " ",
                        ShowIfAvailable(item["address"])),
                    React.createElement("div", null,
                        React.createElement("b", null, "Most recent update by data provider:"),
                        " ",
                        ShowIfAvailable(item["decision_date"]))))))));
};
export default PlanningDataOfficialDataEntry;
