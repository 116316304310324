import React from 'react';
import { Link } from 'react-router-dom';
import './building-edit-summary.css';
import { dataFields } from '../../config/data-fields-config';
import { arrayToDictionary, parseDate } from '../../helpers';
import { CategoryEditSummary } from './category-edit-summary';
function formatDate(dt) {
    return dt.toLocaleString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
}
function enrichHistoryEntries(forwardPatch, reversePatch) {
    return Object
        .entries(forwardPatch)
        .map(([key, value]) => {
        var _a;
        const { title = `Unknown field (${key})`, category = undefined } = (_a = dataFields[key]) !== null && _a !== void 0 ? _a : {};
        return {
            title,
            category,
            value,
            oldValue: reversePatch && reversePatch[key]
        };
    });
}
const BuildingEditSummary = ({ historyEntry, showBuildingId = false, hyperlinkCategories = false }) => {
    const entriesWithMetadata = enrichHistoryEntries(historyEntry.forward_patch, historyEntry.reverse_patch);
    const entriesByCategory = arrayToDictionary(entriesWithMetadata, x => x.category);
    const categoryHyperlinkTemplate = hyperlinkCategories && historyEntry.building_id != undefined ?
        `/edit/$category/${historyEntry.building_id}` :
        undefined;
    return (React.createElement("div", { className: "edit-history-entry" },
        React.createElement("h2", { className: "edit-history-timestamp" },
            "Edited on ",
            formatDate(parseDate(historyEntry.revision_timestamp))),
        React.createElement("h3", { className: "edit-history-username" },
            "By ",
            historyEntry.username),
        showBuildingId && historyEntry.building_id != undefined &&
            React.createElement("h3", { className: "edit-history-building-id" },
                "Building ",
                React.createElement(Link, { to: `/edit/categories/${historyEntry.building_id}` }, historyEntry.building_id)),
        Object.entries(entriesByCategory).map(([category, fields]) => React.createElement(CategoryEditSummary, { key: category, category: category, fields: fields, hyperlinkCategory: hyperlinkCategories, hyperlinkTemplate: categoryHyperlinkTemplate }))));
};
export { BuildingEditSummary };
