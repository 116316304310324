import React from 'react';
import ErrorBox from '../components/error-box';
import InfoBox from '../components/info-box';
let config = require('../../cc-config.json');
export default class ForgottenPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            success: undefined,
            email: undefined,
            emailUsed: undefined
        };
    }
    handleChange(event) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: undefined, success: undefined });
        const emailSent = this.state.email;
        try {
            const res = await fetch('/api/users/password', {
                method: 'PUT',
                body: JSON.stringify({ email: emailSent }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await res.json();
            if (data.error != undefined) {
                this.setState({ error: data.error });
            }
            else if (data.success === true) {
                this.setState({ success: true, emailUsed: emailSent });
            }
            else {
                this.setState({ error: 'Unexpected result.' });
            }
        }
        catch (err) {
            this.setState({ error: 'Something went wrong.' });
        }
    }
    render() {
        return (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, "Forgotten password"),
                React.createElement("p", null, "Please provide the e-mail address associated with your account. A password reset link will be sent to your mailbox."),
                React.createElement(ErrorBox, { msg: this.state.error }),
                React.createElement(InfoBox, { msg: "" }, this.state.success ?
                    `If the email address is registered on Colouring ${config.cityName}, a password reset link will be sent to ${this.state.emailUsed}. Please check your inbox.` :
                    null),
                React.createElement("form", { onSubmit: e => this.handleSubmit(e) },
                    React.createElement("label", { htmlFor: "email" }, "E-mail"),
                    React.createElement("input", { name: "email", id: "email", className: "form-control", type: "email", placeholder: "Your e-mail address", required: true, onChange: e => this.handleChange(e) }),
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement("input", { type: "submit", value: "Request password reset", className: "btn btn-primary" }))))));
    }
}
