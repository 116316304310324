import React from 'react';
import './about.css';
import Categories from '../building/categories';
import SupporterLogos from '../components/supporter-logos';
let config = require('../../cc-config.json');
const AboutPage = () => (React.createElement("article", null,
    React.createElement("section", { className: "main-col" },
        React.createElement("h1", { className: "h2" }, "Can you help us capture information on every building in the city?"),
        React.createElement("p", null, "How many buildings are there? What are their characteristics? Where are they located and how do they contribute to the city? How adaptable are they? How long will they last, and what are the environmental and socio-economic implications of demolition?"),
        React.createElement("p", null,
            "Colouring ",
            config.cityName,
            " is being designed to address these questions by crowdsourcing and visualising information on London\u2019s buildings. We\u2019re releasing the prototype for testing in the late summer. See the slideshow below for what it will look like."),
        React.createElement("div", { className: "buttons-container btn-center" },
            React.createElement("a", { className: "btn btn-outline-dark btn-lg", href: "#sign-up" }, "Sign up for updates")),
        React.createElement("div", { className: "carousel" },
            React.createElement("button", { className: "carousel-control offscreen-text back" }, "Back"),
            React.createElement("ul", { className: "carousel-content" },
                React.createElement("li", null,
                    React.createElement("img", { src: "images/slide-1-welcome.png", alt: "Welcome" })),
                React.createElement("li", null,
                    React.createElement("img", { src: "images/slide-2-categories.png", alt: "Categories" })),
                React.createElement("li", null,
                    React.createElement("img", { src: "images/slide-3-edit.png", alt: "Add/edit data" })),
                React.createElement("li", null,
                    React.createElement("img", { src: "images/slide-4-view.png", alt: "View maps" })),
                React.createElement("li", null,
                    React.createElement("img", { src: "images/slide-5-download.png", alt: "Download data" })),
                React.createElement("li", null,
                    React.createElement("img", { src: "images/slide-6-showcase.png", alt: "Showcase" })),
                React.createElement("li", null,
                    React.createElement("img", { src: "images/slide-7-partners.png", alt: "Partners" }))),
            React.createElement("button", { className: "carousel-control offscreen-text next" }, "Next")),
        React.createElement("div", { className: "buttons-container btn-center" },
            React.createElement("a", { className: "btn btn-outline-dark btn-lg", href: "files/colouring-london-online-exhibition.pdf" }, "View online exhibition"))),
    React.createElement("hr", null),
    React.createElement("section", { className: "main-col" },
        React.createElement("p", null, "Colouring Cities is being designed and built by the Centre for Advanced Spatial Analysis (CASA), University College London and funded by Historic England. Ordnance Survey is providing building footprints required to collect the data, facilitated by the GLA, and giving access to its API and technical support. It will launch in 2019."),
        React.createElement(SupporterLogos, null)),
    React.createElement("hr", null),
    React.createElement("div", { className: "main-col" },
        React.createElement("h2", { className: "h1" }, "Data Categories"),
        React.createElement("p", null, "12 categories have been chosen in consultation with specialists working in a range of areas, from energy analysis and sustainable urban planning and design to building conservation, community planning, architecture and historical research."),
        React.createElement(Categories, { building_id: 2503371, mode: "view" })),
    React.createElement("hr", null),
    React.createElement("div", { className: "main-col" },
        React.createElement("h2", { className: "h1" }, "Once built, our platform will allow you to:")),
    React.createElement("section", { className: "pale-pink color-block" },
        React.createElement("div", { className: "main-col" },
            React.createElement("h3", { className: "h2" }, "View maps"),
            React.createElement("p", null, "To view the data, navigate to the \u2018View Maps\u2019 page and find the category that interests you."),
            React.createElement("img", { className: "border-image", src: "images/slide-4-view.png", alt: "Preview of view maps page" }))),
    React.createElement("section", { className: "pale-yellow color-block" },
        React.createElement("div", { className: "main-col" },
            React.createElement("h3", { className: "h2" }, "Add and edit data"),
            React.createElement("p", null, "Find a building and add or edit data for any of the 12 core categories."),
            React.createElement("img", { className: "border-image", src: "images/slide-3-edit.png", alt: "Preview of add/edit data page" }))),
    React.createElement("section", { className: "pale-orange color-block" },
        React.createElement("div", { className: "main-col" },
            React.createElement("h3", { className: "h2" }, "See how people are using our data"),
            React.createElement("p", null, "Find links to visualisations and analysis, art projects and applications relating to the evolution of London, housing, energy, planning, heritage and history\u2014or something we haven\u2019t imagined yet."),
            React.createElement("img", { className: "border-image", src: "images/slide-6-showcase.png", alt: "Preview of data showcase page" }))),
    React.createElement("section", { className: "pale-green color-block" },
        React.createElement("div", { className: "main-col" },
            React.createElement("h3", { className: "h2" }, "Download, remix and reuse"),
            React.createElement("p", null, "Access bulk downloads of data created through the project to use and reuse under a liberal open data license. Let us know and we\u2019ll feature showcase projects on the Colouring Cities site."),
            React.createElement("img", { className: "border-image", src: "images/slide-5-download.png", alt: "Preview of download page" }))),
    React.createElement("div", { className: "main-col" },
        React.createElement("form", { id: "sign-up", action: "https://tinyletter.com/colouringlondon", method: "post", target: "popupwindow", onSubmit: function () {
                window.open('https://tinyletter.com/colouringlondon', 'popupwindow', 'scrollbars=yes,width=800,height=600');
                return true;
            } },
            React.createElement("h3", { className: "h1" }, "Keep in touch"),
            React.createElement("p", null,
                "Receive occasional newsletters about the project as it develops. You can read previous newsletters in our ",
                React.createElement("a", { href: "https://tinyletter.com/colouringlondon/archive" }, "newsletter archive"),
                "."),
            React.createElement("label", { htmlFor: "tlemail" }, "Enter your email address:"),
            React.createElement("input", { className: "form-control", type: "email", name: "email", id: "tlemail", placeholder: "name@example.com" }),
            React.createElement("input", { type: "hidden", value: "1", name: "embed" }),
            React.createElement("small", { className: "form-text text-muted" },
                React.createElement("a", { href: "https://tinyletter.com" }, "powered by TinyLetter"),
                ". We\u2019ll never share your email address."),
            React.createElement("div", { className: "buttons-container" },
                React.createElement("input", { className: "btn btn-outline-dark btn-block", type: "submit", value: "Sign up for updates" }))))));
export default AboutPage;
