import Markdown from 'markdown-to-jsx';
import React from 'react';
import Tooltip from '../../components/tooltip';
import './data-title.css';
const DataTitle = (props) => {
    return (React.createElement("dt", null,
        props.title,
        props.tooltip ? React.createElement(Tooltip, { text: props.tooltip }) : null));
};
const DataTitleCopyable = (props) => {
    var _a;
    return (React.createElement("div", { className: "data-title" },
        React.createElement("div", { className: "data-title-text" },
            React.createElement("label", { htmlFor: `${props.slug}${(_a = props.slugModifier) !== null && _a !== void 0 ? _a : ''}` },
                React.createElement(Markdown, null, props.title))),
        React.createElement("div", { className: "data-title-actions icon-buttons" },
            (props.copy && props.copy.copying && props.slug && !props.disabled) ?
                React.createElement("label", { className: "icon-button copy" },
                    "Copy",
                    React.createElement("input", { type: "checkbox", checked: props.copy.copyingKey(props.slug), onChange: () => props.copy.toggleCopyAttribute(props.slug) }))
                : null,
            props.tooltip ? React.createElement(Tooltip, { text: props.tooltip }) : null)));
};
export default DataTitle;
export { DataTitleCopyable };
