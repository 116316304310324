import { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch, generatePath } from 'react-router';
export function useUrlParam(param, paramTransform, pathPattern, defaultParams = {}) {
    const match = useRouteMatch();
    const history = useHistory();
    const [paramValue, setParamValue] = useState();
    useEffect(() => {
        const stringValue = match.params[param];
        setParamValue(stringValue && paramTransform.fromParam(stringValue));
    }, [param, paramTransform, match.url]);
    const setUrlParam = useCallback((value) => {
        const newParams = Object.assign({}, defaultParams, match.params);
        newParams[param] = value == undefined ? undefined : paramTransform.toParam(value);
        const newPath = generatePath(pathPattern !== null && pathPattern !== void 0 ? pathPattern : match.path, newParams);
        history.push(newPath);
    }, [param, paramTransform, pathPattern, defaultParams, match.url]);
    return [paramValue, setUrlParam];
}
