import React from 'react';
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import { AuthRoute, PrivateRoute } from './route';
import { AuthProvider } from './auth-context';
import { DisplayPreferencesProvider } from './displayPreferences-context';
import { Header } from './header';
import { MapApp } from './map-app';
import AboutPage from './pages/about';
import ChangesPage from './pages/changes';
import CodeOfConductPage from './pages/code-of-conduct';
import ContactPage from './pages/contact';
import ContributorAgreementPage from './pages/contributor-agreement';
import DataAccuracyPage from './pages/data-accuracy';
import DataExtracts from './pages/data-extracts';
import LeaderboardPage from './pages/leaderboard';
import OrdnanceSurveyLicencePage from './pages/ordnance-survey-licence';
import OrdnanceSurveyUprnPage from './pages/ordnance-survey-uprn';
import PrivacyPolicyPage from './pages/privacy-policy';
import ForgottenPassword from './user/forgotten-password';
import { Login } from './user/login';
import { MyAccountPage } from './user/my-account';
import PasswordReset from './user/password-reset';
import { SignUp } from './user/signup';
import { NotFound } from './pages/not-found';
/**
 * App component
 *
 * This is the top-level stateful frontend component
 * - rendered from props, instantiated either server-side in server.js or client-side in
 *   client.js
 * - state (including user, current building) is initialised from props
 * - callbacks to update top-level state are passed down to subcomponents
 * - render method wraps a react-router switch - this drives which version of the sidebar and
 *   map or other pages are rendered, based on the URL. Use a react-router-dom <Link /> in
 *   child components to navigate without a full page reload.
 */
export const App = props => {
    const mapAppPaths = ['/', '/:mode(view|edit|multi-edit)/:category/:building(\\d+)?/(history)?'];
    return (React.createElement(DisplayPreferencesProvider, null,
        React.createElement(AuthProvider, { preloadedUser: props.user },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: mapAppPaths },
                    React.createElement(Header, { animateLogo: false })),
                React.createElement(Route, null,
                    React.createElement(Header, { animateLogo: true }))),
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: "/about.html", component: AboutPage }),
                React.createElement(AuthRoute, { exact: true, path: "/login.html", component: Login }),
                React.createElement(AuthRoute, { exact: true, path: "/forgotten-password.html", component: ForgottenPassword }),
                React.createElement(AuthRoute, { exact: true, path: "/password-reset.html", component: PasswordReset }),
                React.createElement(AuthRoute, { exact: true, path: "/sign-up.html", component: SignUp }),
                React.createElement(PrivateRoute, { exact: true, path: "/my-account.html", component: MyAccountPage }),
                React.createElement(Route, { exact: true, path: "/privacy-policy.html", component: PrivacyPolicyPage }),
                React.createElement(Route, { exact: true, path: "/contributor-agreement.html", component: ContributorAgreementPage }),
                React.createElement(Route, { exact: true, path: "/ordnance-survey-licence.html", component: OrdnanceSurveyLicencePage }),
                React.createElement(Route, { exact: true, path: "/ordnance-survey-uprn.html", component: OrdnanceSurveyUprnPage }),
                React.createElement(Route, { exact: true, path: "/data-accuracy.html", component: DataAccuracyPage }),
                React.createElement(Route, { exact: true, path: "/data-extracts.html", component: DataExtracts }),
                React.createElement(Route, { exact: true, path: "/contact.html", component: ContactPage }),
                React.createElement(Route, { exact: true, path: "/code-of-conduct.html", component: CodeOfConductPage }),
                React.createElement(Route, { exact: true, path: "/leaderboard.html", component: LeaderboardPage }),
                React.createElement(Route, { exact: true, path: "/history.html", component: ChangesPage }),
                React.createElement(Route, { exact: true, path: mapAppPaths },
                    React.createElement(MapApp, { building: props.building, user_verified: props.user_verified, revisionId: props.revisionId })),
                React.createElement(Route, { component: NotFound })))));
};
