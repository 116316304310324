import React from 'react';
import { DataTitleCopyable } from './data-title';
const UserOpinionEntry = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, title: props.title, copy: props.copy }),
        React.createElement("label", { className: "form-check-label" },
            React.createElement("input", { className: "form-check-input", type: "checkbox", name: props.slug, checked: !!props.userValue, disabled: props.mode === 'view', onChange: e => props.onChange(props.slug, e.target.checked) }),
            " Yes (tick to add or remove your edit)")));
};
export default UserOpinionEntry;
