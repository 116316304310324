import React from 'react';
import './building-edit-summary.css';
import { dataFields } from '../../config/data-fields-config';
import { arrayToDictionary, parseDate } from '../../helpers';
function formatDate(dt) {
    return dt.toLocaleString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
}
function enrichHistoryEntries(forwardPatch, reversePatch) {
    return Object
        .entries(forwardPatch)
        .map(([key, value]) => {
        var _a;
        const { title = `Unknown field (${key})`, category = undefined } = (_a = dataFields[key]) !== null && _a !== void 0 ? _a : {};
        return {
            title,
            category,
            value,
            oldValue: reversePatch && reversePatch[key]
        };
    });
}
const BuildingEditLatest = ({ historyEntry, showBuildingId = false, hyperlinkCategories = false }) => {
    const entriesWithMetadata = enrichHistoryEntries(historyEntry.forward_patch, historyEntry.reverse_patch);
    const entriesByCategory = arrayToDictionary(entriesWithMetadata, x => x.category);
    const categoryHyperlinkTemplate = hyperlinkCategories && historyEntry.building_id != undefined ?
        `/edit/$category/${historyEntry.building_id}` :
        undefined;
    return (React.createElement("h2", { className: "edit-history-timestamp" },
        "Building last edited on ",
        formatDate(parseDate(historyEntry.revision_timestamp))));
};
export { BuildingEditLatest };
