import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { FieldRow } from '../../data-components/field-row';
import DataEntry from '../../data-components/data-entry';
import { dataFields } from '../../../config/data-fields-config';
import SelectDataEntry from '../../data-components/select-data-entry';
import { MultiDataEntry } from '../../data-components/multi-data-entry/multi-data-entry';
import { NumberRangeDataEntry } from '../../data-components/number-range-data-entry';
import './dynamics-data-entry.css';
import { CloseIcon } from '../../../components/icons';
import { DataTitleCopyable } from '../../data-components/data-title';
export const DynamicsBuildingPane = ({ children, className }) => (React.createElement("div", { className: `dynamics-building-pane ${className !== null && className !== void 0 ? className : ''}` }, children));
function lifespan(a, b) {
    if (a == undefined || b == undefined)
        return undefined;
    const diff = a - b;
    return Math.max(diff, 0);
}
function formatRange(minSpan, maxSpan) {
    if (minSpan == undefined || maxSpan == undefined)
        return '';
    if (minSpan === maxSpan)
        return minSpan + '';
    return `${minSpan}-${maxSpan}`;
}
const DynamicsDataRow = ({ value = {}, onChange, disabled = false, maxYear, minYear, mode, required = false, validateForm = false, index }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const onFieldChange = useCallback((key, val) => {
        const changedValue = Object.assign({}, value);
        changedValue[key] = val;
        onChange(changedValue);
    }, [value, onChange]);
    const maxLifespan = lifespan((_a = value.year_demolished) === null || _a === void 0 ? void 0 : _a.max, (_b = value.year_constructed) === null || _b === void 0 ? void 0 : _b.min);
    const minLifespan = lifespan((_c = value.year_demolished) === null || _c === void 0 ? void 0 : _c.min, (_d = value.year_constructed) === null || _d === void 0 ? void 0 : _d.max);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldRow, null,
            React.createElement("div", null,
                React.createElement(NumberRangeDataEntry, { slug: 'year_constructed', slugModifier: index, title: dataFields.demolished_buildings.items.year_constructed.title, onChange: onFieldChange, value: value.year_constructed, disabled: disabled, max: (_f = (_e = value.year_demolished) === null || _e === void 0 ? void 0 : _e.min) !== null && _f !== void 0 ? _f : maxYear, min: minYear, placeholderMin: 'Earliest', placeholderMax: 'Latest', titleMin: `${dataFields.demolished_buildings.items.year_constructed.title}: earliest estimate`, titleMax: `${dataFields.demolished_buildings.items.year_constructed.title}: latest estimate`, required: required })),
            React.createElement("div", null,
                React.createElement(NumberRangeDataEntry, { slug: 'year_demolished', slugModifier: index, title: dataFields.demolished_buildings.items.year_demolished.title, onChange: onFieldChange, value: value.year_demolished, disabled: disabled, max: maxYear, min: (_h = (_g = value.year_constructed) === null || _g === void 0 ? void 0 : _g.max) !== null && _h !== void 0 ? _h : minYear, placeholderMin: 'Earliest', placeholderMax: 'Latest', titleMin: `${dataFields.demolished_buildings.items.year_demolished.title}: earliest estimate`, titleMax: `${dataFields.demolished_buildings.items.year_demolished.title}: latest estimate`, required: required })),
            React.createElement("div", { className: 'lifespan-entry' },
                React.createElement(DataEntry, { slug: 'lifespan', slugModifier: index, title: dataFields.demolished_buildings.items.lifespan.title, value: formatRange(minLifespan, maxLifespan), disabled: true }))),
        React.createElement(SelectDataEntry, { slug: 'overlap_present', slugModifier: index, title: dataFields.demolished_buildings.items.overlap_present.title, onChange: onFieldChange, value: value.overlap_present, options: [
                { value: '1%', label: '1% - almost no overlap with current site' },
                '25%',
                '50%',
                '75%',
                { value: '100%', label: '100% - fully contained in current site' }
            ], disabled: disabled, required: required }),
        React.createElement(MultiDataEntry, { slug: 'links', slugModifier: index, title: dataFields.demolished_buildings.items.links.title, onChange: onFieldChange, value: value.links, disabled: disabled, editableEntries: true, mode: mode, required: required })));
};
function isValid(val) {
    var _a, _b, _c, _d;
    if (val == undefined)
        return false;
    if (typeof ((_a = val.year_constructed) === null || _a === void 0 ? void 0 : _a.min) !== 'number')
        return false;
    if (typeof ((_b = val.year_constructed) === null || _b === void 0 ? void 0 : _b.max) !== 'number')
        return false;
    if (typeof ((_c = val.year_demolished) === null || _c === void 0 ? void 0 : _c.min) !== 'number')
        return false;
    if (typeof ((_d = val.year_demolished) === null || _d === void 0 ? void 0 : _d.max) !== 'number')
        return false;
    if (val.overlap_present == undefined)
        return false;
    if (val.links == undefined || val.links.length < 1)
        return false;
    return true;
}
export const DynamicsDataEntry = (props) => {
    var _a;
    const [newValue, setNewValue] = useState();
    const values = (_a = props.value) !== null && _a !== void 0 ? _a : [];
    const isEditing = props.mode === 'edit';
    const isDisabled = !isEditing || props.disabled;
    const isEdited = !_.isEmpty(newValue);
    const valid = isValid(newValue);
    const addNew = useCallback(() => {
        const val = Object.assign({}, newValue);
        setNewValue(undefined);
        props.onSaveAdd(props.slug, val);
    }, [values, newValue]);
    const edit = useCallback((id, val) => {
        const editedValues = [...values];
        editedValues.splice(id, 1, val);
        props.onChange(props.slug, editedValues);
    }, [values]);
    const remove = useCallback((id) => {
        const editedValues = [...values];
        editedValues.splice(id, 1);
        props.onChange(props.slug, editedValues);
    }, [values]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            isEditing ?
                React.createElement(React.Fragment, null,
                    React.createElement("h6", { className: "h6" }, "Existing records for demolished buildings"),
                    React.createElement("label", null, "Please supply sources for any edits of existing records")) :
                React.createElement(DataTitleCopyable, { slug: props.slug, title: props.title, tooltip: null }),
            React.createElement("ul", { className: "data-entry-list" },
                values.length === 0 &&
                    React.createElement("div", { className: "input-group" },
                        React.createElement("input", { className: "form-control no-entries", type: "text", value: "No records so far", disabled: true })),
                values.map((pastBuilding, id) => (React.createElement("li", { key: id },
                    React.createElement(DynamicsBuildingPane, null,
                        React.createElement("label", null, "Demolished building"),
                        !isDisabled &&
                            React.createElement("button", { type: "button", className: "btn btn-outline-dark delete-record-button", title: "Delete Record", onClick: () => remove(id), "data-index": id },
                                React.createElement(CloseIcon, null)),
                        React.createElement(DynamicsDataRow, { value: pastBuilding, disabled: !props.editableEntries || isDisabled, onChange: (value) => edit(id, value), minYear: props.minYear, maxYear: props.maxYear, mode: props.mode, required: true, index: id })))))),
            !isDisabled &&
                React.createElement("div", { className: 'new-record-section' },
                    React.createElement("h6", { className: "h6" }, "Add a new demolished building record"),
                    React.createElement(DynamicsBuildingPane, { className: 'new-record' },
                        React.createElement(DynamicsDataRow, { value: newValue, onChange: setNewValue, disabled: isDisabled, minYear: props.minYear, maxYear: props.maxYear, mode: props.mode }),
                        React.createElement("label", null, "Please save all your edits before navigating away from the currently selected building - these will be erased otherwise."),
                        React.createElement("button", { type: "button", className: "btn btn-primary btn-block add-record-button", title: "Add to list", onClick: addNew, disabled: !valid || props.hasEdits }, props.hasEdits ?
                            'Save or discard edits first to add a new record' :
                            (isEdited && !valid) ?
                                'Fill in all fields to save record' :
                                'Save new record'))))));
};
