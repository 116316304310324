import React, { useEffect, useState } from 'react';
import './edit-history.css';
import { apiGet } from '../../apiHelpers';
import ContainerHeader from '../container-header';
import { BuildingEditSummary } from './building-edit-summary';
const EditHistory = (props) => {
    const [history, setHistory] = useState(undefined);
    useEffect(() => {
        const fetchData = async () => {
            const { history } = await apiGet(`/api/buildings/${props.building.building_id}/history.json`);
            setHistory(history);
        };
        if (props.building != undefined) { // only call fn if there is a building provided
            fetchData(); // define and call, because effect cannot return anything and an async fn always returns a Promise
        }
    }, [props.building]); // only re-run effect on building prop change
    return (React.createElement(React.Fragment, null,
        React.createElement(ContainerHeader, { title: "Edit history", backLink: '.', cat: 'edit-history' }),
        React.createElement("ul", { className: "edit-history-list" }, history && history.map(entry => (React.createElement("li", { key: `${entry.revision_id}`, className: "edit-history-list-element" },
            React.createElement(BuildingEditSummary, { historyEntry: entry })))))));
};
export { EditHistory };
