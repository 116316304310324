import React from 'react';
let ccconfig = require('../../cc-config.json');
const ContactPage = () => (React.createElement("article", null,
    React.createElement("section", { className: "main-col" },
        React.createElement("h1", { className: "h2" }, "Contact"),
        React.createElement("p", null, " Colouring Cities has been designed as a sustainable, low-cost model for knowledge exchange/open data platforms able to be reproduced by other towns and cities using our open platform code."),
        React.createElement("p", null, " It is being developed by a small, dedicated team at The Alan Turing Institute. We are unable to answer individual queries but welcome constructive comments on how to improve the site, and on other types of data and new features you might like to see."),
        React.createElement("p", null,
            " To view our technical site and platform code please visit Github at: ",
            React.createElement("a", { href: ccconfig.githubURL }, "https://github.com/colouring-cities/colouring-core"),
            "."),
        React.createElement("p", null,
            "For contact regarding Colouring Sweden, please reach out to ",
            React.createElement("a", { href: "https://www.mdu.se/staff?id=ahs02" }, "Alan Hawas at M\u00E4lardalen University")))));
export default ContactPage;
