import { useUrlParam } from './use-url-param';
export function useUrlCategoryParam() {
    return useUrlParam('category', {
        fromParam: (x) => {
            // TODO: add validation
            return x;
        },
        toParam: (x) => {
            return x;
        }
    });
}
