import { apiPost } from '../apiHelpers';
import { buildingUserFields, dataFields } from '../config/data-fields-config';
function makeUpdateData(edits) {
    const data = {
        attributes: {},
        user_attributes: {}
    };
    for (let [field, value] of Object.entries(edits)) {
        if (dataFields[field]) {
            data.attributes[field] = value;
        }
        else if (buildingUserFields[field]) {
            data.user_attributes[field] = value;
        }
    }
    return data;
}
export async function sendBuildingUpdate(buildingId, edits) {
    const requestData = makeUpdateData(edits);
    const data = await apiPost(`/api/buildings/${buildingId}.json`, requestData);
    if (data.error) {
        throw data.error;
    }
    else {
        const { revision_id, attributes, user_attributes } = data;
        return Object.assign(Object.assign({ revision_id }, attributes), user_attributes);
    }
}
