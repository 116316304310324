import React, { useState } from 'react';
import { AutofillDropdown } from './autofill-dropdown/autofill-dropdown';
export const DataEntryInput = props => {
    const [isEditing, setEditing] = useState(false);
    const nameAttr = props.name || props.slug;
    const idAttr = props.id || props.slug;
    const transformValue = (value) => {
        const transform = props.valueTransform || (x => x);
        const transformedValue = value === '' ?
            null :
            transform(value);
        return transformedValue;
    };
    const handleChange = (value) => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, props.slug, transformValue(value));
    };
    const handleConfirm = () => {
        var _a;
        (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props, props.slug, props.value);
    };
    const handleAutofillSelect = (value) => {
        var _a, _b;
        const transformedValue = transformValue(value);
        if (props.confirmOnAutofillSelect) {
            (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props, props.slug, transformedValue);
        }
        else {
            (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, props.slug, transformedValue);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { className: "form-control", type: props.isUrl ? "url" : "text", id: idAttr, name: nameAttr, value: props.value || '', maxLength: props.maxLength, required: props.required, disabled: props.disabled, placeholder: props.placeholder, onKeyDown: e => {
                if (e.keyCode === 13) {
                    if (props.confirmOnEnter) {
                        handleConfirm();
                    }
                }
            }, onChange: e => handleChange(e.target.value), onInput: e => setEditing(true), onFocus: e => setEditing(true), onBlur: e => setEditing(false) }),
        props.autofill &&
            React.createElement(AutofillDropdown, { editing: isEditing, onSelect: handleAutofillSelect, onClose: () => setEditing(false), fieldName: props.slug, fieldValue: props.value, showAllOptionsOnEmpty: props.showAllOptionsOnEmpty })));
};
