import React from 'react';
import { useAuth } from '../auth-context';
import { categoriesConfig } from '../config/categories-config';
import { categoryUiConfig } from '../config/category-ui-config';
import BuildingNotFound from './building-not-found';
/**
 * Top-level container for building view/edit form
 *
 * @param props
 */
const BuildingView = (props) => {
    const { user } = useAuth();
    const DataContainer = categoryUiConfig[props.cat];
    const categoryConfig = categoriesConfig[props.cat];
    if (categoryConfig == undefined) {
        return React.createElement(BuildingNotFound, { mode: "view" });
    }
    const { name, aboutUrl, intro, inactive = false } = categoryConfig;
    return React.createElement(DataContainer, Object.assign({}, props, { title: name, help: aboutUrl, intro: intro, inactive: inactive, user: user, mapColourScale: props.mapColourScale, onMapColourScale: props.onMapColourScale }));
};
export default BuildingView;
