import React, { Fragment } from 'react';
import { DataTitleCopyable } from './data-title';
function makeOption(option) {
    if (typeof option !== 'string' && 'value' in option && 'label' in option) {
        return option;
    }
    else
        return {
            value: option,
            label: option
        };
}
const SelectDataEntry = (props) => {
    var _a;
    const slugWithModifier = props.slug + ((_a = props.slugModifier) !== null && _a !== void 0 ? _a : '');
    const options = props.options.map(makeOption);
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        React.createElement("select", { className: "form-control", id: slugWithModifier, name: slugWithModifier, value: props.value || '', disabled: props.mode === 'view' || props.disabled, required: props.required, onChange: e => props.onChange(props.slug, e.target.value === '' ?
                null :
                e.target.value) },
            React.createElement("option", { value: "" }, props.placeholder),
            options.map(option => (React.createElement("option", { key: option.value, value: option.value }, option.label))))));
};
export default SelectDataEntry;
