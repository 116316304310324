import React from 'react';
import { Link } from 'react-router-dom';
import { useMultiEditData } from '../hooks/use-multi-edit-data';
import ErrorBox from '../components/error-box';
import InfoBox from '../components/info-box';
import { allFieldsConfig } from '../config/data-fields-config';
import DataEntry from './data-components/data-entry';
const MultiEdit = (props) => {
    const [data, error] = useMultiEditData();
    return (React.createElement("section", { className: 'data-section' },
        React.createElement("header", { className: `section-header view ${props.category} background-${props.category}` },
            React.createElement("h2", { className: "h2" },
                "Paste ",
                props.category,
                " data")),
        React.createElement("div", { className: "section-body" },
            React.createElement("form", null,
                error ?
                    React.createElement(ErrorBox, { msg: error }) :
                    React.createElement(InfoBox, { msg: 'Click buildings one at a time to colour using the data below' }),
                data &&
                    Object.keys(data).map((key => {
                        var _a, _b;
                        return (React.createElement(DataEntry, { key: key, title: (_b = (_a = allFieldsConfig[key]) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : `Unknown field (${key})`, slug: key, disabled: true, value: data[key] }));
                    }))),
            React.createElement("form", { className: 'buttons-container' },
                React.createElement(Link, { to: `/view/${props.category}`, className: 'btn btn-secondary' }, "Back to view"),
                React.createElement(Link, { to: `/edit/${props.category}`, className: 'btn btn-secondary' }, "Back to edit")))));
};
export default MultiEdit;
