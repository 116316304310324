import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth-context';
import ConfirmationModal from '../components/confirmation-modal';
import ErrorBox from '../components/error-box';
import { SpinnerIcon } from '../components/icons';
let config = require('../../cc-config.json');
export const MyAccountPage = () => {
    const { isLoading, user, userError, logout, generateApiKey, deleteAccount } = useAuth();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [error, setError] = useState(undefined);
    const handleLogout = useCallback((e) => {
        e.preventDefault();
        logout(setError);
    }, [logout]);
    const handleGenerateKey = useCallback(async (e) => {
        e.preventDefault();
        setError(undefined);
        generateApiKey(setError);
    }, [generateApiKey]);
    const handleDeleteAccount = useCallback(() => {
        setError(undefined);
        deleteAccount(setError);
    }, [deleteAccount]);
    if (!user && isLoading) {
        return (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement(SpinnerIcon, { spin: true }),
                " Loading user info...")));
    }
    const issuesURL = config.githubURL + "/issues";
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            !isLoading && React.createElement(ErrorBox, { msg: userError }),
            !userError && (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: "h1" },
                    "Welcome, ",
                    user.username,
                    "!"),
                React.createElement("p", null,
                    "Colouring ",
                    config.cityName,
                    " is under active development. Please",
                    ' ',
                    React.createElement("a", { href: "https://discuss.colouring.london/" }, "discuss suggestions for improvements"),
                    " and",
                    ' ',
                    React.createElement("a", { href: issuesURL }, " report issues or problems"),
                    "."),
                React.createElement("p", null,
                    "For reference, here are the",
                    ' ',
                    React.createElement(Link, { to: "/privacy-policy.html" }, "privacy policy"),
                    ",",
                    ' ',
                    React.createElement(Link, { to: "/contributor-agreement.html" }, "contributor agreement"),
                    " and",
                    ' ',
                    React.createElement(Link, { to: "/data-accuracy.html" }, "data accuracy agreement"),
                    "."),
                React.createElement(ErrorBox, { msg: error }),
                React.createElement("form", { onSubmit: handleLogout },
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement(Link, { to: "/edit/age", className: "btn btn-warning" }, "Start colouring"),
                        React.createElement("input", { className: "btn btn-secondary", type: "submit", value: "Log out" }))),
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, "My Details"),
                React.createElement("h3", { className: "h3" }, "Username"),
                React.createElement("p", null, user.username),
                React.createElement("h3", { className: "h3" }, "Email Address"),
                React.createElement("p", null, user.email || '-'),
                React.createElement("h3", { className: "h3" }, "Registered"),
                React.createElement("p", null, user.registered.toString()),
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, "Technical details"),
                React.createElement("p", null, "Are you a software developer? If so, you might be interested in these."),
                React.createElement("h3", { className: "h3" }, "API key"),
                React.createElement("p", null, user.api_key || '-'),
                React.createElement("form", { onSubmit: handleGenerateKey, className: "form-group mb-3" },
                    React.createElement("input", { className: "btn btn-warning", type: "submit", value: "Generate API key" })),
                React.createElement("h3", { className: "h3" }, "Open Source Code"),
                "Colouring ",
                config.cityName,
                " site code is developed at ",
                React.createElement("a", { href: config.githubURL }, "colouring-cities"),
                " on Github",
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, "Account actions"),
                React.createElement("form", { onSubmit: e => {
                        e.preventDefault();
                        setShowDeleteConfirm(true);
                    }, className: "form-group mb-3" },
                    React.createElement("input", { className: "btn btn-danger", type: "submit", value: "Delete account" })),
                React.createElement(ConfirmationModal, { show: showDeleteConfirm, title: "Confirm account deletion", description: "Are you sure you want to delete your account? This cannot be undone.", confirmButtonText: "Delete account", confirmButtonClass: "btn-danger", onConfirm: () => handleDeleteAccount(), onCancel: () => setShowDeleteConfirm(false) }))))));
};
