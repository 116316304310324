import React, { useState } from 'react';
import { DataTitleCopyable } from './data-title';
import { FieldRow } from './field-row';
import './number-range-data-entry.css';
export const NumberRangeDataEntry = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { min: minValue, max: maxValue } = (_a = props.value) !== null && _a !== void 0 ? _a : {};
    const [minEdited, setMinEdited] = useState(minValue != undefined);
    const [maxEdited, setMaxEdited] = useState(maxValue != undefined);
    const isDisabled = props.mode === 'view' || props.disabled;
    const slugWithModifier = props.slug + ((_b = props.slugModifier) !== null && _b !== void 0 ? _b : '');
    return (React.createElement("div", null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        React.createElement(FieldRow, null,
            React.createElement("input", { className: "form-control min-number-input", type: "number", id: slugWithModifier, name: slugWithModifier, value: minValue !== null && minValue !== void 0 ? minValue : '', step: (_c = props.step) !== null && _c !== void 0 ? _c : 1, max: (_d = (maxEdited ? maxValue : null)) !== null && _d !== void 0 ? _d : props.max, min: props.min, disabled: isDisabled, placeholder: props.placeholderMin, title: props.titleMin, required: props.required, onChange: e => {
                    const val = e.target.value === '' ? null : parseFloat(e.target.value);
                    setMinEdited(val != undefined);
                    props.onChange(props.slug, {
                        min: val,
                        max: maxEdited ? maxValue : val
                    });
                } }),
            React.createElement("input", { className: "form-control max-number-input", type: "number", id: `${props.slug}-max`, name: `${props.slug}-max`, value: maxValue !== null && maxValue !== void 0 ? maxValue : '', step: (_e = props.step) !== null && _e !== void 0 ? _e : 1, max: props.max, min: (_f = (minEdited ? minValue : null)) !== null && _f !== void 0 ? _f : props.min, disabled: isDisabled, placeholder: props.placeholderMax, title: props.titleMax, required: props.required, onChange: e => {
                    const val = e.target.value === '' ? null : parseFloat(e.target.value);
                    setMaxEdited(val != undefined);
                    props.onChange(props.slug, {
                        min: minEdited ? minValue : val,
                        max: val
                    });
                } }))));
};
