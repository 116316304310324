import React, { Fragment, useState } from "react";
import './data-entry-group.css';
import { DownIcon, RightIcon } from "../../components/icons";
const DataEntryGroup = (props) => {
    const { collapsed: initialCollapsed = true, showCount = true } = props;
    const [collapsed, setCollapsed] = useState(initialCollapsed);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'data-entry-group-header', onClick: () => setCollapsed(!collapsed) },
            React.createElement(CollapseIcon, { collapsed: collapsed }),
            React.createElement("span", { className: 'data-entry-group-title' }, props.name)),
        React.createElement("div", { className: `data-entry-group-body ${collapsed ? 'collapse' : ''}` }, props.children)));
};
const CollapseIcon = (props) => (React.createElement("span", { className: "collapse-icon" }, props.collapsed ? React.createElement(RightIcon, null) : React.createElement(DownIcon, null)));
export { DataEntryGroup };
