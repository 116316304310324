var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import './confirmation-modal.css';
const ConfirmationModal = (_a) => {
    var { confirmButtonText = 'OK', confirmButtonClass = 'btn-primary', cancelButtonClass = '' } = _a, props = __rest(_a, ["confirmButtonText", "confirmButtonClass", "cancelButtonClass"]);
    const modalShowClass = props.show ? 'modal-show' : 'modal-hide';
    return (React.createElement("div", { className: `modal ${modalShowClass}`, tabIndex: -1, role: "dialog" },
        React.createElement("div", { className: "modal-backdrop" },
            React.createElement("div", { className: "modal-dialog", role: "document" },
                React.createElement("div", { className: "modal-content" },
                    React.createElement("div", { className: "modal-header" },
                        React.createElement("h5", { className: "modal-title" }, props.title),
                        React.createElement("button", { type: "button", className: "close", "aria-label": "Close", onClick: () => props.onCancel() },
                            React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))),
                    React.createElement("div", { className: "modal-body" },
                        React.createElement("p", null, props.description)),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement("button", { type: "button", className: `btn btn-block ${confirmButtonClass}`, onClick: () => props.onConfirm() }, confirmButtonText),
                        React.createElement("button", { type: "button", className: `btn btn-block ${cancelButtonClass}`, onClick: () => props.onCancel() }, "Cancel")))))));
};
export default ConfirmationModal;
