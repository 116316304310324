import React from 'react';
function formatValue(value) {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    }
    if (Array.isArray(value)) {
        return value.map(v => `"${v}"`).join(', ');
    }
    return value;
}
function isComplex(x) {
    return x != undefined && (Array.isArray(x) || typeof x === 'object');
}
const ObjectDiffSummary = ({ oldValue, newValue }) => (React.createElement(React.Fragment, null,
    React.createElement("pre", { title: "Value before edit", className: 'edit-history-diff old' }, JSON.stringify(oldValue, null, 4)),
    React.createElement("pre", { title: "Value after edit", className: 'edit-history-diff new' }, JSON.stringify(newValue, null, 4))));
const SimpleSummary = (props) => (React.createElement(React.Fragment, null,
    React.createElement("code", { title: "Value before edit", className: 'edit-history-diff old' }, formatValue(props.oldValue)),
    "\u00A0",
    React.createElement("code", { title: "Value after edit", className: 'edit-history-diff new' }, formatValue(props.newValue))));
const FieldEditSummary = props => (React.createElement(React.Fragment, null,
    props.title,
    ":\u00A0",
    (isComplex(props.oldValue) || isComplex(props.value)) ?
        React.createElement(ObjectDiffSummary, { oldValue: props.oldValue, newValue: props.value }) :
        React.createElement(SimpleSummary, { oldValue: props.oldValue, newValue: props.value })));
export { FieldEditSummary };
